<template>
  <div class="page">
    <!--查询框-->
    <div class="search-box section page-header" style="margin-bottom: 1px;height: auto;">
      <el-form ref="searchForm" :inline="true" :model="query" size="medium">
        <el-form-item prop="parkingLotId">
          <!-- <SuggestParking v-model="query.parkingLotId" class="width260" /> -->
          <el-select v-model="query.parkingLotId"   placeholder="请选择车场"
             filterable class="width260">
            <el-option v-for="(item, index) in parkingList" :key="index" :label="item.name"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="resetQueryForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--信息统计框-->
    <!-- <div class="search-box section page-header height50 padding20-32">
      <div class="content">
        <div class="content-item">
          <div class="title">信息条数：</div>
          <div class="text">{{ summaryData.total }}</div>
        </div>
      </div>
    </div> -->

    <div class="content-box section">
      <div v-if="dataList.length > 0">
        <!--        v-loading="isLoading"-->
        <!-- <el-table
            :data="dataList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column
              align="center"
              label="车场名称"
              prop="parkingLotName"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="出/入口名称"
              prop="name"
              width="240"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="出/入口类型"
              prop="type"
              width="120"
          >
            <template slot-scope="scope">
              {{scope.row.type==0?'入口':'出口'}}
            </template>
</el-table-column>
<el-table-column align="center" label="IP地址" prop="ip" width="140">
</el-table-column>
<el-table-column align="center" label="登录账号" prop="username" width="140">
</el-table-column>
<el-table-column align="center" label="登录密码" prop="password" width="140">
</el-table-column>
<el-table-column align="center" label="操作" fixed="right" prop="name" width="140">
  <template slot-scope="scope">
              <el-button
                  class="common-text font-size14"
                  size="small"
                  type="text"
                  @click="showQrCodeDialog(scope.row)"
              >车道二维码</el-button>
              <el-button
                  class="common-text font-size14"
                  size="small"
                  type="text"
                  @click="operateLiftingRod(scope.row.id)"
              >抬杆</el-button>
              <el-button
                  class="common-text font-size14"
                  size="small"
                  type="text"
              >视频播放</el-button>
            </template>
</el-table-column>
</el-table> -->
        <p class="table-title">出入口总览</p>
        <el-row class="card-table">
          <el-col :span="6" v-for="item, index in dataList" :key="index" style="padding: 0 10px;">
            <el-card class="box-card">
              <div slot="header" class="card-header">
                <span>{{ item.name }}</span>
              </div>
              <div class="item-box flx">
                <div class="item-round"></div>
                <p>出入口类型<span class="card-scope"> {{ item.type == 0 ? '入口' : '出口' }}</span></p>
              </div>
              <div class="item-box flx">
                <div class="item-round"></div>
                <p>IP地址<span class="card-scope"> {{ item.ip }}</span></p>
              </div>
              <div class="item-box flx">
                <div class="item-round"></div>
                <p>登录账号<span class="card-scope"> {{ item.username }}</span></p>
              </div>
              <div class="item-box flx">
                <div class="item-round"></div>
                <p>登陆密码<span class="card-scope"> {{ item.password }}</span></p>
              </div>
              <div class="card-bottom flx">
                <div class="bottom-box" @click="handlePhone(item.id)">抓拍</div>
                <div class="bottom-box" @click="showQrCodeDialog(item)">车道二维码</div>
                <div class="bottom-box" @click="operateLiftingRod(item.id, 1)">落杆</div>
                <div class="bottom-box" @click="operateLiftingRod(item.id, 0)">抬杆</div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <p v-else>暂无数据</p>
      <!-- <div class="pagination-box">
        <el-pagination
            :current-page="pagination.pageNo"
            :page-size="pagination.pageSize"
            :total="total"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div> -->
    </div>

    <el-dialog :append-to-body="true" :before-close="qrCodeDialogBeforeClosed" :visible.sync="qrCodeDialogVisible"
      class="common-dialog" title="下载二维码" width="240px">
      <div>
        <QrCode :id="'QrCode'" :codeUrl="curQrCode" />
      </div>
    </el-dialog>

    <el-dialog :append-to-body="true" :before-close="qrCodeDialogBeforeClosed" :visible.sync="showPhone"
      class="common-phone" title="抓拍" width="1000px">
      <div class="common-box" style="width: 100%;">
        <el-image v-loading="!disablePhone" ref="phoneRef" class="back-img" @load="phoneLoad" @error="phoneError" :preview-src-list="[phoneUrl]" :src="phoneUrl" alt="" />
        <!-- <img class="back-img" src="@/assets/images/illustration.png" alt=""> -->
         
        <div class="common-posi  flx"  @click="handleGetPhone">
          <img src="@/assets/images/pict-action.png" alt="">
          <p class="common-posi-title">抓拍</p>
        </div>
      </div>
    </el-dialog>

    <!-- 修改车牌弹窗 -->
    <el-dialog :append-to-body="true" :before-close="modifyDialogBeforeClosed" :visible.sync="modifyDialogFormVisible"
      class="common-dialog" title="修改车牌" width="550px">
      <el-form ref="modifyForm" :model="modifyForm" :rules="modifyRules" label-width="100px">
        <el-form-item label="车牌颜色" required>
          <el-form-item prop="color">
            <el-select v-model="modifyForm.color" placeholder="请选择车牌颜色">
              <el-option label="黄牌" value="01"></el-option>
              <el-option label="蓝牌" value="02"></el-option>
              <el-option label="黑牌" value="06"></el-option>
              <el-option label="绿色" value="03"></el-option>
              <el-option label="黄绿" value="04"></el-option>
              <el-option label="白牌" value="23"></el-option>
              <el-option label="其他" value="99"></el-option>
            </el-select>
          </el-form-item>
        </el-form-item>
        <el-form-item label="车牌号码" prop="numPlate">
          <el-input v-model="modifyForm.numPlate"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="reason">
          <el-input v-model="modifyForm.reason" maxlength="100" show-word-limit placeholder="请输入备注"
            type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeModify">取消</el-button>
        <el-button type="primary" @click="modifySubmit">确定</el-button>
      </div>
    </el-dialog>
    <!-- 导出弹窗 -->
    <el-dialog :append-to-body="true" :visible.sync="exportDialogFormVisible" class="common-dialog" title="导出选项"
      width="600px">
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input v-model="form.exportSize" placeholder="请填写导出条数"></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QrCode from "@/components/QrCode.vue";
// import SuggestParking from "@/components/SuggestParking";
import { addParkingOrderExit, updateVehicleNumPlate,getPhotoGraph } from "@/api/parkingOrder";
import {
  searchParkingLotGateByParamPage,
  getParkingQrCode,
  operationParkingLotGate,
} from "@/api/entranceMonitoring";
// import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";
import { dateFormat } from "@/utils/utils";
import { doExport } from "@/api/common";
import {
  checkToken,
  getParkingLot
} from "@/api/common";
import { Message, MessageBox } from "element-ui";

export default {
  name: "parking_order_inside",
  mixins: [Permission],
  data() {
    return {
      dataList: [],
      parkingList: [],
      summaryData: {},
      // isLoading: false,
      curQrCode: '',
      qrCodeDialogVisible: false, //控制二维码弹窗
      modifyDialogFormVisible: false, //修改车牌弹窗
      total: "0",
      discountAmountStats: "0",
      receivableAmountStats: "0",
      transactionAmountStats: "0",
      // 完结表单
      completeForm: {
        parkingOrderIntoId: "",
        endTime: "",
      },
      rules: {
        endTime: [
          { required: true, message: "请选择日期时间", trigger: "change" },
        ],
      },
      //修改车牌表单
      modifyForm: {
        parkingOrderIntoId: "",
        parkingSpaceId: "",
        color: "",
        numPlate: "",
        reason: "",
      },
      modifyRules: {
        color: [
          { required: true, message: "请选择车牌颜色", trigger: "change" },
        ],
        numPlate: [
          { required: true, message: "请输入车牌号码", trigger: "blur" },
        ],
        reason: [
          { required: true, message: "请输入修改原因", trigger: "blur" },
          { max: 100, message: "长度不能超过100个字符", trigger: "blur" },
        ],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < new Date().getTime() - 24 * 3600 * 1000 * 90
            || time.getTime() > new Date().getTime();
        },
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      dialogFormVisible: false, //控制审核弹窗层显示
      photoDialogFormVisible: false,
      orderType: {
        // 0 临停，1白名单，2黑名单，3长租，4预存，5访客，6共享
        0: "临停",
        1: "白名单",
        2: "黑名单",
        3: "长租",
        4: "预存",
        5: "访客",
        6: "共享",
      },
      orderStatus: {
        0: "正常",
        // 1: "无效",
        // 2: "审核",
        // 3: "复议",
        // "": "正常",
      },
      payStatus: {
        // "": "",
        '0': "欠费",
        '1': "不欠费",
        '2': "其他",
      },
      query: {
        parkingLotId: "", //停车场id
        pageNo: 1,
        pageSize: 10
      },
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
      showPhone:false,
      phoneUrl:"",
      id:"",
      disablePhone:true,
      maxPhone:0,
      showPhoneLoad:false,
    };
  },
  components: {
    // SuggestParking,
    QrCode,
  },
  async mounted() {
    const res = await getParkingLot();
    if (res && res.code === 30 && res.result) {
      this.query.parkingLotId = res.returnObject.parkingLotList[0].id;
      this.parkingList = res.returnObject.parkingLotList;
    }
    this.search()
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    },
    //显示二维码
    showQrCodeDialog(item) {
      getParkingQrCode({
        parkingLotId: item.parkingLotId,
        parkingLotGateId: item.id,
        qrCodeType: 0,
      }).then((res) => {
        if (res && res.code === 30 && res.result) {
          console.log(res)
          this.curQrCode = res.returnObject
          this.qrCodeDialogVisible = true
        }
      });
    },
    phoneError(event){
      // console.log(this.$refs.phoneRef.src);
      console.log(event);
      this.maxPhone += 1;
      if(this.maxPhone < 5){
        setTimeout(()=>{
          // event.target.src = this.phoneUrl; // 设置新的src尝试重新加载 
          this.$refs.phoneRef.src = this.phoneUrl;
        },100)
        console.error('图片加载失败，已尝试重新加载。');  
      }
    },
    phoneLoad(){
      console.log("图片加载完成");
      this.maxPhone = 0;
    },
    handlePhone(id){
      this.id = id;
      this.handleGetPhone();
    },
    // 抓拍
    handleGetPhone(){
      // 抓拍接口延迟
      if(this.disablePhone){
        this.disablePhone = false;
        this.showPhone = true;
        getPhotoGraph({parkingLotGateId:this.id}).then(res=>{
          // Message.info("");
          if(res && res.code == 30){
            Message.success("抓拍成功");
            setTimeout(()=>{
              this.phoneUrl = res.returnObject || res.message;
            },200)
            this.disablePhone = true;
          }
        })
      }else{
        Message.info("请等待");
      }
     
    },
    //重置查询条件
    resetQueryForm() {
      // this.query = {
      //   parkingLotId: "", //停车场id
      //   pageNo: 1,
      //   pageSize: 10
      // };
      this.query.pageNo = 1;
      this.query.pageSize = 10;
      this.getNowDate();
      this.doSearch();
    },
    doSearch() {
      this.query.pageNo = 1;
      this.search();
    },
    async search() {
      checkToken()
        .then(async res => {
          console.log('checkToken', res)
          if (!res || res.code !== 30) {
            MessageBox.alert('您的登录信息已超时，请重新登录！', '系统提示', {
              confirmButtonText: '重新登录',
              callback: () => {
                window.localStorage.clear()
                window.$vue.$router.push('/login')
              }
            });
          } else {
            this.isLoading = true;
            // const params = this.paramFormat(this.query);
            const res = await searchParkingLotGateByParamPage(this.query);
            this.isLoading = false;
            if (res && res.code === 30 && res.result) {
              const returnObject = res.returnObject;
              if (returnObject !== "") {
                this.dataList = returnObject.records;
                this.summaryData = returnObject
                // this.pagination.totalSize = returnObject.total;
                this.total = returnObject.total;
                this.form.exportSize = returnObject.total;
              } else {
                this.dataList = [];
                // this.pagination.totalSize = 0;
                this.total = 0;
                this.discountAmountStats = 0;
                this.receivableAmountStats = 0;
                this.transactionAmountStats = 0;
              }
            }
          }
        })
    },
    //完结弹出窗
    operateLiftingRod(parkingLotGateId, type) {
      operationParkingLotGate({
        parkingLotGateId, type
      }).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
        }
      });
    },
    //添加完结
    submitForm() {
      this.$refs.completeForm.validate((valid) => {
        if (valid) {
          const parkingOrderIntoId = this.completeForm.parkingOrderIntoId;
          const endTime = this.completeForm.endTime;
          console.log(endTime);
          addParkingOrderExit(parkingOrderIntoId, endTime).then((res) => {
            if (res && res.code === 30 && res.result) {
              this.$message({
                message: "完结成功",
                type: "success",
              });
              this.qrCodeDialogVisible = false;
              this.doSearch();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //关闭完结弹窗清空表单
    qrCodeDialogBeforeClosed(done) {
      this.resetQueryForm()
      done();
    },

    //修改车牌提交
    modifySubmit() {
      this.$refs.modifyForm.validate((valid) => {
        if (valid) {
          const parkingOrderIntoId = this.modifyForm.parkingOrderIntoId;
          const color = this.modifyForm.color;
          const numPlate = this.modifyForm.numPlate;
          const reason = this.modifyForm.reason;
          const parkingSpaceId = this.modifyForm.parkingSpaceId;

          updateVehicleNumPlate(
            parkingOrderIntoId,
            parkingSpaceId,
            numPlate,
            color,
            reason
          ).then((res) => {
            if (res && res.code === 30 && res.result) {
              this.$message({
                message: "修改车牌成功",
                type: "success",
              });
              this.modifyDialogFormVisible = false;
              this.doSearch();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //修改车牌弹窗关闭时清理表单
    modifyDialogBeforeClosed(done) {
      this.resetQueryForm()
      done();
    },
    //关闭修改车牌
    closeModify() {
      this.modifyDialogFormVisible = false;
      this.resetQueryForm()
    },
    // handleSizeChange(val) {
    //   console.log(`每页 ${val} 条`);
    // },
    // handleCurrentChange(val) {
    //   console.log(`当前页: ${val}`);
    // },
    //修改车牌弹窗
    modifyLicensePlate(parkingOrderIntoId, parkingSpaceId) {
      this.modifyDialogFormVisible = true;
      this.modifyForm.parkingOrderIntoId = parkingOrderIntoId;
      this.modifyForm.parkingSpaceId = parkingSpaceId;
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = this.paramFormat(this.query);
      if (this.query.timeValues.length > 0) {
        params["startTime"] = this.query.timeValues[0];
        params["endTime"] = this.query.timeValues[1];
      }
      params["isExport"] = "1";
      params["exportSize"] = this.form.exportSize;
      // const res = searchIncomeFlow(params);
      // var text = window.location.hostname;
      // console.log(text);
      // window.open("http://172.16.69.129:81" + "/ParkingManagermentController/getEsParkingOrderRecord?" + objectToQueryString(params));
      doExport(
        "/ParkingManagermentController/getEsParkingOrderRecord",
        params,
        "路内停车订单.xlsx"
      );
      loading.close();
      this.exportDialogFormVisible = false;

      // if (res && res.code === 30 && res.result) {
      //   loading.close();
      //   this.exportDialogFormVisible = false;
      // }
    },
    //获取当前时间
    getNowDate() {
      const beginToday = dateFormat("yyyy-mm-dd", new Date());
      const beginDate = beginToday;
      const endToday = dateFormat("yyyy-mm-dd", new Date());
      const endDate = endToday;
      this.query.timeValues = [beginDate, endDate];
    },
  },
};
</script>

<style lang="less" scoped>
.photoShow {
  display: flex;
  flex-direction: row;

  .photo-width {
    /deep/ .el-carousel__container {
      width: 430px;
    }
  }

  .driveInPhoto {
    margin: 0 34px;
  }

  .driveOutPhoto {
    margin: 0 26px;
  }
}

/deep/ .el-button--text {
  color: #0768FD !important;
}

/deep/ .el-card__header {
  background: rgba(7, 104, 253, 0.04);
  border-bottom: none;
}

.table-title {
  font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  line-height: 24px;
  margin-left: 15px;
  margin-bottom: 20px;
}

.card-table {
  display: flex;
  align-items: center;
  // justify-content: space-around;
  flex-wrap: wrap;

  .box-card {
    width: 378px;
    margin-bottom: 30px;
    // margin-right: 16px;

    .card-header {
      font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB;
      font-weight: 600;
      font-size: 16px;
      color: #000000;
    }

    .item-box {
      font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB;
      font-weight: 400;
      font-size: 14px;
      color: #5F666C;
      margin-bottom: 20px;
    }

    .item-round {
      width: 6px;
      height: 6px;
      background: #C5D1CB;
      border-radius: 50%;
      margin-right: 7px;
    }

    .card-scope {
      font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
      margin-left: 11px;
    }

    .card-bottom {
      justify-content: space-between;

      .bottom-box {
        background: rgba(7, 104, 253, 0.04);
        border-radius: 4px;
        font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB;
        font-weight: 400;
        font-size: 12px;
        color: #0768FD;
        padding: 8px 10px;
        min-width: 60px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}

.flx {
  display: flex;
  align-items: center;
}
.common-box{
  height: 576px;
  position: relative;
  overflow: hidden;
  .back-img{
    width: 100%;
    height: 100%;
  }
  .common-posi{
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;

    padding: 13px 28px;
    background: #0768FD;
    border-radius: 67px 67px 67px 67px;
    border: 3px solid #CFE2FF;

    font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    img{
      width: 25px;
      height: 25px;
      margin-right: 12px;
    }
  }
}
</style>
